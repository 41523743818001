import {gql} from "@apollo/client";
import {client} from "./client";

export const getNewToken = () => {
    return client.mutate({
        mutation: gql`
mutation generateTokens {
  generateTokens{
    accessToken
  }
}
`
    }).then((response) => {
        localStorage.setItem('accessToken', response.data.generateTokens.accessToken)
        console.log('new token generated. token is: ', response.data.generateTokens.accessToken)
    }).catch((res) => {
        console.log('error during getting new token:', res)
        localStorage.removeItem('accessToken')
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    });
};

export const MUTATION_UPLOAD = gql`mutation M($file: Upload!, $groupId: Int!, $uploadType: UploadType!, $videoOptions: VideoOptions) {
  uploadFile(file: $file, groupId: $groupId, uploadType: $uploadType, videoOptions: $videoOptions) {
    id
    groupId
    fileType
    savedName
    originalName
    md5
    resolution
    duration
    mimetype
    size
    isReady
    createdAt
  }
}`

export const MUTATION_UPLOADS = gql`mutation(
  $files: [Upload!]
  $groupId: Int!
  $uploadType: UploadType!
  $videoOptionArray: [VideoOptions]
) {
  uploadFiles(
    files: $files
    groupId: $groupId
    uploadType: $uploadType
    videoOptionArray: $videoOptionArray
  ) {
    id
    groupId
    fileType
    savedName
    originalName
    md5
    resolution
    duration
    mimetype
    size
    isReady
    createdAt
  }
}
`
