import {gql} from "@apollo/client";

export const MUTATION_REGISTER_WITH_CODE = gql`mutation registerWithCode(
  $username: String!
  $password: String!
  $token: String!
  $name: String!
) {
  registerWithCode(
    username: $username
    password: $password
    token: $token
    name: $name
  )
}`

export const MUTATION_LOGIN = gql`
mutation RootMutationType($loginUsername: String!, $loginPassword: String!,$rememberMe: Boolean) {
    Login(username: $loginUsername, password: $loginPassword, rememberMe: $rememberMe) {
      accessToken
    }
  }
`;
