import {gql} from "@apollo/client";


export const QUERY_GROUP = gql(`query drUser($userId: Int) {
  drUser(id: $userId) {
    username
    group {
      id
      name
      stores {
        id
        name
        screens {
          id
        }
        miniPcs {
          cameras {
            id
            name
            cameraType
          }
        }
        clients {
          id
        }
        sdcCameras{
          id
          name
          cameraType
        }
      }
    }
  }
}
`)
