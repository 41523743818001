import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import {ApolloProvider,} from '@apollo/client';
import {client} from './graphql/client';
import {DRContextSnack} from "./types";
import {GraphQLError} from "graphql";
import {BrowserRouter as Router} from "react-router-dom";
import App from './App';

export const mutationErrorMessage: (err: { graphQLErrors: GraphQLError[] }) => DRContextSnack = (err: any) => {
    console.log(err)
    if (err.networkError) return {
        message: "Sunucuya bağlanırken hata",
        severity: "error", open: true
    }
    switch (err.graphQLErrors[0].extensions?.code) {
        case "SCREENNOTFOUND":
            return {
                message: "Aktivasyon kodu hatalı.",
                severity: "error", open: true
            }
        case "USEREXISTS":
            return {
                message: "Lütfen başka kullanıcı adı giriniz.",
                severity: "error", open: true
            }
        case "USERNOTFOUND":
            return {
                message: "Hatalı giriş",
                severity: "error", open: true
            }
        case "UNAUTHENTICATED":
            return {
                message: "Hatalı giriş",
                severity: "error", open: true
            }
        case "BADPASSWORD":
            return {
                message: "Şifre en az 6 haneli olmalı.",
                severity: "error", open: true
            }
        case "TOOSHORT":
            return {
                message: "Şifre en az 6 hane olmalı.",
                severity: "error", open: true
            }
        default:
            return {
                message: `Bir hata oluştu. Lütfen sistem yöneticisine başvurun: ${err.graphQLErrors[0].extensions?.code}`,
                severity: "error", open: true
            }
    }
}


ReactDOM.render(
    // <React.StrictMode>
    <MuiPickersUtilsProvider utils={DayjsUtils}>
        <ApolloProvider client={client}>
            <Router>
                <App/>
            </Router>
        </ApolloProvider>
    </MuiPickersUtilsProvider>
    // </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


//reportWebVitals();
