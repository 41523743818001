import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
            boxShadow: "rgb(0 0 0 / 20%) 0px 12px 28px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px, rgb(255 255 255 / 5%) 0px 0px 0px 1px inset",
            borderRadius: 4,
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(2),

        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        checkbox: {
            margin: theme.spacing(2),
        },
        button: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        avatar: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
        filterTextField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        stickToBottom: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
            color: '#fff'
        },

        headerRoot: {
            display: 'flex',
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        flex: {
            flexGrow: 1
        },
        rightSide: {
            margin: theme.spacing(1,2,1,2),
        },
        greeting: {
            fontWeight: 600,
            fontSize: 18,
            marginRight: theme.spacing(1)
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        notRoot: {
            flexGrow: 1,
            padding: 10,
            margin: theme.spacing(2, 0, 5, 0)
        },
    })
);
