import {makeStyles, withStyles} from "@material-ui/core/styles";
import {createStyles, Switch, Theme} from "@material-ui/core";
import image3 from "../../images/image3.png";
import TextField from "@material-ui/core/TextField";

const vdf = true;

export const LoginSwitch = withStyles({
    switchBase: {
        color: "#848484",
        '&$checked': {
            color: vdf ? "#FA8072" : "#40DFD0",
        },
        '&$checked + $track': {
            backgroundColor: vdf ? "#FA8072" : "#40DFD0",
        },
    },
    checked: {},
    track: {},
})(Switch);

export const LoginInput = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#848484',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: vdf ? "#FA8072" : "#40DFD0",
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: vdf ? "#FA8072" : "#40DFD0",
            },
        },
    },
})(TextField);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            backgroundImage: 'url(' + image3 + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            margin: theme.spacing(0, 0, 0, 9)
        },
        paper: {
            margin: theme.spacing(6, 0, 4, 0),
            padding: theme.spacing(1, 3, 3, 3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
            borderRadius: 4
        },
        signForm: {
            margin: theme.spacing(6, 0, 0, 0),
        },
        container: {
            backgroundColor: "#f4f4f4"
        },
    })
);
