import {unstable_createMuiStrictModeTheme as createMuiTheme} from "@material-ui/core";

console.log(window.location.href)

// {(window.location.hostname === 'localhost') ?
export const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Vodafone Regular',
        ].join(','),
        button: {
            textTransform: 'none',
            fontSize: 14,
            textAlign: "center",
        },
        allVariants: {
            color: "#333"
        },
    },
    palette: {
        primary: {
            main: "#e60000",
            contrastText: '#fff',
            // light:"#FA8072",
        },
        secondary: {
            main: "#4A4D4E",
            contrastText: '#fff',
        },
    },
});


export const themeDT = createMuiTheme({
    typography: {
        fontFamily: [
            'Vodafone Regular',
        ].join(','),
        button: {
            textTransform: 'none',
            fontSize: 14,
            textAlign: "center",
        },
        allVariants: {
            color: "#333"
        },
    },
    palette: {
        primary: {
            main: "#1ac5db",
            contrastText: '#fff',
            // light:"#FA8072",
        },
        secondary: {
            main: "#4CE3BB",
            contrastText: '#fff',
        },
    },
});
