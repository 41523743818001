import {ApolloClient, from, fromPromise, InMemoryCache} from "@apollo/client";
import {createUploadLink} from 'apollo-upload-client';

import {onError} from "@apollo/client/link/error";
import {setContext} from "@apollo/client/link/context";
import {getNewToken} from "./mutations";

export const apiUrl = process.env.REACT_APP_API_URL
export const cloudUrl = process.env.REACT_APP_CLOUD_URL
export const vodafoneDomainName = process.env.REACT_APP_DOMAIN_NAME || 'dijital-magaza.dt.net.tr'

const graphQLEndpoint = `${apiUrl}/graphql`


const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
        for (const {extensions} of graphQLErrors!) {
            // console.log('luls', extensions)
            // mutationErrorMessage(extensions?.code)
            if (extensions!.code === 'TOKEN_EXPIRED') {
                return fromPromise(
                    getNewToken()
                ).flatMap(() => {
                    return forward(operation)
                })
            } else if (extensions!.code === 'REFRESH_INVALID') {
                return
            }
        }
    }
    return forward(operation)
});


const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem('accessToken')
    // console.log('we took the token, and the token is...:', token)
    return {
        headers: {
            ...headers,
            "Digital-Token": token ? `Bearer ${token}` : "",
        }
    }
});


const parseHeaders = (rawHeaders: any) => {
    const headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
    preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
        const parts = line.split(":");
        const key = parts.shift().trim();
        if (key) {
            const value = parts.join(":").trim();
            headers.append(key, value);
        }
    });
    return headers;
};

export const uploadFetch = (url: string, options: any) =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const opts: any = {
                status: xhr.status,
                statusText: xhr.statusText,
                headers: parseHeaders(xhr.getAllResponseHeaders() || "")
            };
            opts.url =
                "responseURL" in xhr
                    ? xhr.responseURL
                    : opts.headers.get("X-Request-URL");
            const body = "response" in xhr ? xhr.response : (xhr as any).responseText;
            resolve(new Response(body, opts));
        };
        xhr.onerror = () => {
            reject(new TypeError("Network request failed"));
        };
        xhr.ontimeout = () => {
            reject(new TypeError("Network request failed"));
        };
        xhr.open(options.method, url, true);

        Object.keys(options.headers).forEach(key => {
            xhr.setRequestHeader(key, options.headers[key]);
        });

        if (xhr.upload) {
            xhr.upload.onprogress = options.onProgress;
        }

        // options.onAbortPossible(() => {
        //     xhr.abort();
        // });

        xhr.send(options.body);
    });

const customFetch = (uri: any, options: any) => {
    if (options.useUpload) {
        return uploadFetch(uri, options);
    }
    return fetch(uri, options);
};


const uploadLink = createUploadLink({
    uri: graphQLEndpoint,
    credentials: 'include',
    fetch: customFetch as any
})


export const client = new ApolloClient({
    link: from([errorLink, authLink, uploadLink]),
    credentials: 'include',
    cache: new InMemoryCache(),
    connectToDevTools: true
});
