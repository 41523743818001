import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Breadcrumbs as MUBreadCrumbs} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import {withRouter} from "react-router-dom"


const namesToTurkish: (name:string)=>string = name => {
    switch (name) {
        case "ekran-yonetimi":
            return "Ekran Yönetimi"
        case "müsteri-memnuniyet-analizi":
            return "Müsteri Memnuniyet Analizi"
        case "soru-seti-yayınlama" :
            return "Soru Seti Yayınlama"
        case "anket-yönetimi" :
            return "Anket Yönetimi"
        case "emoji-yönetimi" :
            return "Emoji Yönetimi"
        case "icerikler" :
            return "İçerikler"
        case "goruntuler" :
            return "Görüntüler"
        case "signage" :
            return "İçerik Düzenle"
        case "insight" :
            return "Müşteri Öngörüsü"
        case "report" :
            return "Raporlar"
        case "target-group" :
            return "Hedef Kitle Analizi"
        case "profile" :
            return "Profil Sayfası"
        default:
            return ""
    }
}

const Breadcrumbs = (props:any) => {

    const {history , location: { pathname }} = props

    const pathnames = pathname.split("/").filter((x:any) => x)


    return (
        <MUBreadCrumbs aria-label="breadcrumb" style={{cursor:"pointer"}}>
            {(pathname === "/") ? "" : <Link onClick={() => history.push("/")}>Ana Sayfa</Link>}
            {pathnames.map((names:any , index:any) => {
                const routeTo = `/${pathnames.slice(0, index+1).join("/")}`
                return <Link key={names} onClick={() => history.push(routeTo)}>{namesToTurkish(names)}</Link>
            })}
        </MUBreadCrumbs>
    );
}


export default withRouter(Breadcrumbs);
