import React from "react";
import {ThreeDots, useLoading, Rings} from '@agney/react-loading';
import LoadingOverlay from 'react-loading-overlay-ts';
import Header from "./Header";
import {isAuthenticated} from "../App";

const Loading = (props: any) => {

    const {containerProps, indicatorEl} = useLoading({
        loading: true,
        // @ts-ignore
        indicator: <Rings width={"400"}/>,
    });

    if (props.overlay)
        return (
            <LoadingOverlay>
                {indicatorEl}
            </LoadingOverlay>
        )

    return (
        <div>
            {isAuthenticated() ? <Header/> : null}
            <section {...containerProps}>
                {indicatorEl}
            </section>
        </div>
    )
}

export default Loading
