import React, {useContext} from 'react';
import {withStyles} from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as Icon from 'react-bootstrap-icons';
import {Badge} from "@material-ui/core";
import '../index.css';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from "react-router-dom";
import {useStyles} from "./ComponentStyles";
import {contextDR} from "../App";

const StyledMenu = withStyles({})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: "#FFE0DD",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            },
        },
    },
}))(MenuItem);


const DropDown = () => {
    const {setGroupId,setUserId} = useContext(contextDR)
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <Badge
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <Icon.PersonCircle size={35} color={'#333'} className={classes.avatar}/>
            </Badge>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClick={handleClose}
            >
                <Link to='/profile' style={{textDecoration: 'none'}}>
                    <StyledMenuItem button>
                        <ListItemIcon><Icon.PersonCircle size={20}/></ListItemIcon>
                        <ListItemText>Profil</ListItemText>
                    </StyledMenuItem>
                </Link>

                <Link to="/login" onClick={() => {
                    localStorage.removeItem('accessToken') //TODO serverside cookieyi sil
                    setGroupId(0)
                    setUserId(0)
                }} style={{textDecoration: 'none'}}>
                    <StyledMenuItem button>
                        <ListItemIcon><Icon.BoxArrowRight size={20}/></ListItemIcon>
                        <ListItemText>Çıkış Yap</ListItemText>
                    </StyledMenuItem>
                </Link>
            </StyledMenu>
        </div>
    );
}

export default DropDown;
