import React, {FunctionComponent, useContext} from "react";
import {Slide, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {DRContextSnack} from "../types";
import {contextDR} from "../App";

const DrSnackbar: FunctionComponent<DRContextSnack> = props => {
    const {setSnack} = useContext(contextDR)
    return <Snackbar
        anchorOrigin={props.anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'left',
        }}
        autoHideDuration={props.autoHideDuration || 3000}
        TransitionComponent={Slide}
        onClose={() => setSnack(prevState => ({...prevState, open: false}))}
        {...props}
    >
        <Alert severity={props.severity || "info"}>
            {props.message}
        </Alert>
    </Snackbar>
}

export default DrSnackbar
