import React, {useContext} from 'react';
import {withStyles} from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import '../index.css';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import * as Icon from "react-bootstrap-icons";
import {theme} from "../theme";
import {useStyles} from "./ComponentStyles"
import {decode} from "jsonwebtoken";
import {contextDR} from "../App";

const StyledMenu = withStyles({})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: "#FFE0DD",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            },
        },
    },
}))(MenuItem);

const rows = [
    {
        id: 1,
        name: "Hedef Kitle Analizi",
        link: '/target-group',
        active: false
    },
    {
        id: 2,
        name: "Müşteri Memnuniyet Analizi",
        link: '/customer-satisfaction',
        active: false
    },
    {
        id: 3,
        name: "Kişi Sayar",
        link: '/customer-counter',
        active: false
    },
    {
        id: 4,
        name: "Ekran Yönetimi",
        link: '/screen',
        active: false
    },
    {
        id: 5,
        name: "Müşteri Öngörüsü",
        link: '/insight',
        active: false
    },
    {
        id: 6,
        name: "Raporlar",
        link: '/report',
        active: false
    },
    {
        id: 7,
        name: "Admin Panel",
        link: '/admin/store-management',
        active: false
    },
]

const HeaderServices = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const {activeServices} = useContext(contextDR)


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const decodedToken: any = decode(localStorage.getItem('accessToken') as string)!
    let admin = false;
    decodedToken.role.forEach((role:any) =>{
        if (role.name === "superAdmin") admin = true
    })
    return (
        <div className={classes.root} style={{marginTop:"7px" , marginLeft:"10px"}}>
            <Button onClick={handleClick}>Servisler
                <Icon.CaretDownFill size={12} style={{marginLeft: theme.spacing(1), color: "rgba(0, 0, 0, 0.54)"}}/>
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClick={handleClose}
            >
                {rows.map(row => {
                    if (activeServices.includes(row.name)) {
                        row.active = true
                    }
                    if (row.id === 7) {
                        if (admin)
                            return (
                                <Link key={row.name} to={row.link} style={{textDecoration: 'none'}}>
                                    <StyledMenuItem button key={row.name}>
                                        <ListItemText>{row.name}</ListItemText>
                                    </StyledMenuItem>
                                </Link>
                            )
                        else return (<></>)
                    }
                    return row.active ?
                        <Link key={row.name} to={row.link} style={{textDecoration: 'none'}}>
                            <StyledMenuItem button key={row.name}>
                                <ListItemText>{row.name}</ListItemText>
                            </StyledMenuItem>
                        </Link> : null
                })}
            </StyledMenu>
        </div>
    );
}


export default HeaderServices;


