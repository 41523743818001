import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import vdf_logo from "../../images/vf_logo.png";
import React, {useContext, useEffect, useState} from "react";
import {useMutation} from '@apollo/client';
import CssBaseline from "@material-ui/core/CssBaseline";
import {LoginInput, LoginSwitch, useStyles} from "./LoginStyles";
import {MUTATION_LOGIN} from "./mutations";
import {contextDR} from "../../App";
import {mutationErrorMessage} from "../../index";
import {decode} from "jsonwebtoken";
import {vodafoneDomainName} from "../../graphql/client";
import vf_logo from "../../images/vf_logo.png";
import logoDT from "../../images/logoDT.png";
// import {version} from '../../autobuild_version.js'

const Login = () => {
    const [vdf , setVdf] = useState(true)
    const {setSnack, setUserId, history} = useContext(contextDR)
    const classes = useStyles();
    const [formState, setFormState] = useState({
        loginUsername: '',
        loginPassword: '',
        rememberMe: true
    });





    const [checkLogin, {loading: mutationLoading}] = useMutation(MUTATION_LOGIN, {
        variables: {
            loginUsername: formState.loginUsername,
            loginPassword: formState.loginPassword,
            rememberMe: formState.rememberMe
        }, fetchPolicy: "no-cache"
    });

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });

    let logo: string | undefined


    useEffect(() => {

        if( window.location.hostname === vodafoneDomainName ){
            document.title ="Vodafoneee Business | Giriş Yap"
            // logo =  vf_logo
        }
        else{
            document.title = "DT Dijital Mağaza çözümleri"
            setVdf(false)
            // logo = logoDT
        }

    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [e.target.name]: e.target.checked});
    };



    return (
        <div className="login-root">
            <Grid container component="main" className="login">
                <CssBaseline/>
                <Grid item xs={12} sm={12} md={4} className={classes.signForm}>
                    <div className="sign-form">
                        <div className={classes.paper}>
                            <img className="vodafone_logo"
                                 src={vdf ? vf_logo : logoDT } alt="Vodafone logo"/>
                            {vdf ? <Typography variant="h6">Dijital Mağazaya Hoşgeldiniz</Typography> : <Typography variant="h6">DT Dijital Mağazaya Hoşgeldiniz</Typography> }
                            <form className={classes.form} noValidate>
                                <LoginInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="username"
                                    color="primary"
                                    label="Kullanıcı Adı"
                                    value={formState.loginUsername}
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState, loginUsername: e.target.value
                                        })
                                    }
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                />
                                <LoginInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label="Şifre"
                                    color="primary"
                                    type="password"
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState, loginPassword: e.target.value
                                        })
                                    }
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <Grid container item alignItems="flex-end" justify="flex-end">
                                    <Grid item xs={6} sm={6} md={6} style={{textAlign: "start"}}>
                                        <Link to={"/kayit"}>
                                            Aktivasyon kodu ile kayıt
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} style={{textAlign: "end"}}>
                                        <Link to={"/forgot-password"}>
                                            Şifremi Unuttum
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="flex-start">
                                    <Grid container item md={6}>
                                        <FormControlLabel
                                            style={{textAlign: 'left'}}
                                            control={<LoginSwitch checked={formState.rememberMe}
                                                                  onChange={(event, bool) => setFormState(prevState => ({
                                                                      ...prevState,
                                                                      rememberMe: bool
                                                                  }))}
                                                                  name="checkedA"/>}
                                            label="Beni Hatırla"
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className={classes.submit}
                                    style={{color:"#fff", backgroundColor: vdf ? "#FA8072" : "#40DFD0"}}
                                    onClick={e => {
                                        e.preventDefault()
                                        checkLogin().then(r => {
                                            localStorage.setItem('accessToken', r.data.Login.accessToken)
                                            const decodedToken: any = decode(r.data.Login.accessToken as string)
                                            setUserId(decodedToken.userId)
                                            history.push('/')
                                        }).catch(e => setSnack(prevState => ({...prevState, ...mutationErrorMessage(e)})));
                                    }}
                                >
                                    Giriş Yap
                                </Button>
                            </form>
                            {mutationLoading && <p>Yükleniyor..</p>}
                        </div>
                    </div>
                    <Typography variant="body2" style={{color: "#4a4d4e"}}>
                        {'Powered by DT Bilişim Grubu ©'}
                        {new Date().getFullYear()}
                        {'. All rights reserved. '}
                        {/*{`${version}`}*/}
                    </Typography>
                </Grid>
                <Grid item xs={false} sm={12} md={7} className={classes.image}/>
            </Grid>
        </div>
    );
}
export default Login;
