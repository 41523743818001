import React, {useContext} from 'react';
import {useTheme, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import vf_logo from "../images/vf_logo.png";
import logoDT from "../images/logoDT.png"
import {Badge, Menu, MenuItem, Typography} from "@material-ui/core";
import DropDown from "./DropDown";
import {Link} from "react-router-dom";
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import '../index.css';
import {gql, useMutation, useQuery} from "@apollo/client";
import HeaderServices from "./HeaderServices";
import ListItemText from '@material-ui/core/ListItemText';
import {useStyles} from "./ComponentStyles";
import {contextDR} from "../App";
import Breadcrumb from "../BreadCrumbs";
import {vodafoneDomainName} from "../graphql/client";

let logo: string | undefined
if( window.location.hostname === vodafoneDomainName )  logo =  vf_logo
else logo = logoDT


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: any) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
const StyledMenuItem = withStyles((theme) => ({root: {},}))(MenuItem);

const QUERY_NOTIFS= gql(
    `query GetNotifs($drNotificationssGroupId: Int) {
        drNotificationss(groupId: $drNotificationssGroupId) {
            id
            groupId
            icon
            notification
            reference
        }
    }`
)

const MUTATION_DELETE_NOTIF = gql`
    mutation DeleteNotificationRootMutationType($deleteNotificationId: Int!) {
        deleteNotification(id: $deleteNotificationId)
    }
`;

const Header = () => {
    const vdf = true;
    const classes = useStyles();
    const {groupId, userName} = useContext(contextDR)
    const theme = useTheme();
    const [notifications, setNotifications] = React.useState([{notification: "", reference: "", id: 0}])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentNotifID, setCurrentNotifID] = React.useState(0)
    const [width, setWidth] = React.useState(window.innerWidth);

    const currentDate = new Date();
    const currentTime = currentDate.getHours();
    let greeting;
    if (currentTime < 12) {
        greeting = "Günaydın";
    } else if (currentTime < 18) {
        greeting = "İyi Günler";
    } else {
        greeting = "İyi Akşamlar";
    }

    const [deleteNotifMutation] = useMutation(MUTATION_DELETE_NOTIF, {
        variables: {deleteNotificationId: currentNotifID},
        errorPolicy: 'all'
    });

    const {data: notification_data, refetch} = useQuery(QUERY_NOTIFS,
        {variables: {drNotificationssGroupId: groupId}});

    React.useEffect(() => {
        if (notification_data) setNotifications(notification_data.drNotificationss)
    }, [notification_data])

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function removeNotification(id: number) {
        await setCurrentNotifID(id);
        await deleteNotifMutation();
        await refetch();
    }

    const handleUpdateWidth = () => {
        setWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleUpdateWidth);
        return () => window.removeEventListener("resize", handleUpdateWidth);
    },[]);


    return (
        (width > 780) ?
            <div className={classes.headerRoot}>
                <CssBaseline/>
                <AppBar className="app-bar" style={{zIndex: 2}}>
                    <Toolbar>
                        <Link to='/' style={{textDecoration: 'none', marginLeft: theme.spacing(2)}}>
                            <img className="header-logo" src={logo} alt="Vodafone logo"/>
                        </Link>

                        <div className="mobile-display">
                            {/*<Select className="store-group" value={selectedGroupId()}*/}
                            {/*        onChange={changeGroupHandler}>*/}
                            {/*    {userGroupsData.drUser.groups.map((group: any) => (*/}
                            {/*        <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>*/}
                            {/*    ))}*/}
                            {/*</Select>*/}
                            <HeaderServices/>

                        </div>
                        <div style={{marginLeft: "30px"}}>
                            <Breadcrumb/>


                        </div>

                        <div className={classes.flex}/>
                        <div className="user-greeting">
                            <Typography variant="h6" className={classes.greeting}>{greeting}</Typography>
                        </div>
                        <div>
                            <Typography>{userName}</Typography>
                        </div>
                        <Badge badgeContent={notifications.length} color="primary" className={classes.rightSide}>
                            <NotificationsRoundedIcon style={{color: "#333", fontSize: 30, cursor: "pointer"}}
                                                      onClick={handleClick}/>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {notifications.map((row, index) => {
                                    return (
                                        <StyledMenuItem key={index}>
                                            <Typography style={{marginRight: 15}}
                                                        onClick={() => removeNotification(row.id)}>X</Typography>
                                            <Link to={row.reference} style={{textDecoration: 'none'}}>
                                                <ListItemText primary={row.notification}/>
                                            </Link>
                                        </StyledMenuItem>
                                    )
                                })}
                            </StyledMenu>
                        </Badge>
                        <div style={{cursor: "pointer"}}>
                            <DropDown/>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.drawerHeader}/>
            </div>
            :
            <div className={classes.headerRoot}>
                <CssBaseline/>
                <AppBar className="app-bar" style={{zIndex: 2}}>
                    <Toolbar>
                        <Link to='/' style={{textDecoration: 'none', marginLeft: theme.spacing(2)}}>
                            <img className="header-logo" src={logo} alt="Vodafone logo - DT logo"/>
                        </Link>

                        <div className="mobile-display">
                            {/*<Select className="store-group" value={selectedGroupId()}*/}
                            {/*        onChange={changeGroupHandler}>*/}
                            {/*    {userGroupsData.drUser.groups.map((group: any) => (*/}
                            {/*        <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>*/}
                            {/*    ))}*/}
                            {/*</Select>*/}
                            <HeaderServices/>

                        </div>
                        {/*<div style={{marginLeft:"30px"}}>*/}
                        {/*    <Breadcrumb  />*/}


                        {/*</div>*/}


                        <div className={classes.flex}/>
                        <div className="user-greeting">
                            <Typography variant="h6" className={classes.greeting}>{greeting}</Typography>
                        </div>
                        <Badge badgeContent={notifications.length} color="primary" className={classes.rightSide}>
                            <NotificationsRoundedIcon style={{color: "#333", fontSize: 30, cursor:"pointer"}} onClick={handleClick}/>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {notifications.map((row, index) => {
                                    return (
                                        <StyledMenuItem key={index}>
                                            <Link to={row.reference} style={{textDecoration: 'none'}}>
                                                <ListItemText primary={row.notification}/>
                                            </Link>
                                            <Typography style={{marginRight: 15}}
                                                        onClick={() => removeNotification(row.id)}>X</Typography>
                                        </StyledMenuItem>
                                    )
                                })}
                            </StyledMenu>
                        </Badge>
                        <div style={{cursor: "pointer"}}>
                            <DropDown/>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.drawerHeader}/>
            </div>


            );
}

export default Header;

