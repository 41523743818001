import React, {createContext, lazy, Suspense, useEffect, useState} from 'react';
import './App.css';
import Header from "./components/Header";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {ThemeProvider} from '@material-ui/core/styles';
// import {theme,themeDT} from "./theme"
import Login from "./pages/Authentication/Login";
import Loading from "./components/Loading";
import DrSnackbar from "./components/DrSnackbar";
import {DRContext, DRContextSnack, GT_Group, QUERY_GROUP_TYPE} from "./types";
import {useQuery} from "@apollo/client";
import {QUERY_GROUP} from "./graphql/queries";
import {decode} from "jsonwebtoken";
import {vodafoneDomainName} from "./graphql/client";

import tawkTo from "tawkto-react";


const Register = lazy(() => import("./pages/Authentication/Register"));
const TargetGroupAnalysis = lazy(() => import("./pages/HedefKitleAnalizi/TargetGroupAnalysis"));
const CustomerSatisfaction = lazy(() => import("./pages/MusteriMemnuniyetAnalizi/CustomerSatisfaction"));
const CreateQuestionSet = lazy(() => import("./pages/MusteriMemnuniyetAnalizi/QuestionSet/CreateQuestionSet"));
const PublishQuestionSet = lazy(() => import("./pages/MusteriMemnuniyetAnalizi/PublishingFeatures/PublishQuestionSet"));
const EmojiManagement = lazy(() => import("./pages/MusteriMemnuniyetAnalizi/EmojiManagement/EmojiManagement"));
const Services = lazy(() => import("./pages/Servisler/Services"));
const CustomerCounter = lazy(() => import("./pages/KisiSayar"));
const Alarm = lazy(() => import("./pages/KisiSayar/Alarm"));
const Reports = lazy(() => import("./pages/Raporlar/Reports"));
const Profile = lazy(() => import("./pages/Profilim"));
const Insight = lazy(() => import("./pages/MusteriOngorusu/Insight"));
const DigitalSignage = lazy(() => import('./pages/digital-signage/DigitalSignage'));
const AdminMain = lazy(() => import("./pages/Admin/AdminMain"));
const AdminSdc = lazy(() => import("./pages/Admin/Sdc"));
const MiniPcManagement = lazy(() => import ( "./pages/Admin/MiniPcPanel"));
const SmartScreenPanel = lazy(() => import ( "./pages/Admin/ScreenPanel"));
const Campaigns = lazy(() => import ("./pages/EkranYonetimi/Campaigns"));
const ScreenManagement = lazy(() => import("./pages/EkranYonetimi/Screens"));
const ScreenCaptures = lazy(() => import ( "./pages/EkranYonetimi/ScreenCaptures"));
const Admin = lazy(() => import ( "./pages/Admin"));
const Terminal = lazy(() => import ( "./pages/Admin/Terminal"));
const KisiSayarSDC = lazy(() => import ( "./pages/KisiSayarSDC"));
const HedefKitleSDC = lazy(() => import ( "./pages/HedefKitleSDC"));

const theme = window.location.hostname === vodafoneDomainName ? require("./theme").theme : require("./theme").themeDT


type ProtectedRouteProps = {
    path: string;
    toRedirect: string;
};

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({path, toRedirect, children,}) => {
    const a = isAuthenticated()
    if (a) return (<Route path={path}>
        {children}
    </Route>)
    else return (<Redirect to={toRedirect}/>)
};

export const isAuthenticated = (): boolean => {
    const token = localStorage.getItem('accessToken');
    return !!token;
}

export const contextDR = createContext<DRContext>({
    snack: {
        severity: "error",
        open: false,
        message: ""
    },
    setSnack: () => ({}),
    groupId: 0,
    setGroupId: () => ({}),
    userId: 0,
    setUserId: () => ({}),
    activeServices: [],
    setActiveServices: () => ({}),
    history: {
        length: 0,
        action: "POP",
        location: {pathname: "", search: "", state: "", hash: ""},
        push: () => ({}),
        goBack: () => ({}),
        replace: () => ({}),
        go: () => ({}),
        goForward: () => ({}),
        block: () => () => null,
        listen: () => () => null,
        createHref: () => ""
    },
    groupData: {},
    setGroupData: () => ({}),
    userData: {},
    setUserData: () => ({}),
    userName: ""
});

const hedefKitleTypes: string[] = ["ag", "agm", "agmr"]
const tawkToPropertyId = '609a54b9b1d5182476b7c8b5'
const tawkToKey = '1f5ddltrm'

const App = () => {

    const [snack, setSnack] = useState<DRContextSnack>({
        severity: "error",
        open: false,
        message: ""
    });


    // const [activeServices, setActiveServices] = useState<Array<string>>(["Raporlar", "Müşteri Öngörüsü"]);
    const [activeServices, setActiveServices] = useState<Array<string>>([]);

    const [groupId, setGroupId] = useState<number>(0)
    const [userId, setUserId] = useState<number>(0)
    const [groupData, setGroupData] = useState<GT_Group | undefined>({stores: []})
    const [userData, setUserData] = useState<any>()
    const [userName, setUserName] = useState<string>("")
    const history = useHistory()

    const {data: userGroupsData, refetch: refetchUserGroupData} = useQuery<QUERY_GROUP_TYPE>(QUERY_GROUP, {
        variables: {userId: userId},
        skip: !userId
    });

    const contextProviderValues = {
        snack,
        setSnack,
        groupId,
        setGroupId,
        userId,
        setUserId,
        activeServices,
        setActiveServices,
        groupData,
        setGroupData,
        history,
        userData,
        setUserData,
        userName
    };


    const decodedToken: any = decode(localStorage.getItem('accessToken') as string)
    // if (!userId && decodedToken) setUserId(decodedToken.userId)
    // if (!userData && decodedToken) setUserData(decodedToken)

    useEffect(() => {
        refetchUserGroupData()
        if (decodedToken) {
            setUserId(decodedToken.userId)
            setUserData(decodedToken)
        }
    }, [userId])

    useEffect(() => {
        if (!userGroupsData) return
        setGroupData(userGroupsData && userGroupsData.drUser.group)
        setUserName(userGroupsData.drUser.username)
    }, [userGroupsData])

    useEffect(() => {
        setActiveServices([])
        if (!groupData) return
        let services: string[] = []
        groupData.stores.forEach(store => {
            if (!services.includes("Müşteri Memnuniyet Analizi") && store.clients.length) services.push("Müşteri Memnuniyet Analizi")
            if (!services.includes("Ekran Yönetimi") && store.screens.length) services.push("Ekran Yönetimi")
            store.miniPcs.forEach(miniPc => {
                if (!services.includes("Hedef Kitle Analizi") && miniPc.cameras.some(camera => hedefKitleTypes.includes(camera.cameraType as string))) services.push("Hedef Kitle Analizi")
                if (!services.includes("Kişi Sayar") && miniPc.cameras.some(camera => camera.cameraType === 'counter')) services.push("Kişi Sayar")
            })
            store.sdcCameras.forEach(sdcCamera => {
                if (!services.includes("Hedef Kitle Analizi") && sdcCamera.cameraType === "agm") services.push("Hedef Kitle Analizi")
                if (!services.includes("Kişi Sayar") && sdcCamera.cameraType === "counter") services.push("Kişi Sayar")
            })
        })
        setActiveServices(services)
        if (!groupData.id) return
        setGroupId(groupData.id)

    }, [groupData])

    useEffect(() => {
        // if (process.env.REACT_APP_USE_TAWKTO)
        tawkTo(tawkToPropertyId, tawkToKey)
    }, [])


    // if (!groupId && userGroupsData) {
    //     if (!userGroupsData.drUser) {
    //         localStorage.removeItem('accessToken')
    //         setSnack(prevState => ({
    //             ...prevState,
    //             open: true,
    //             severity: "warning",
    //             message: "Girişiniz sonlandırıldı. Lütfen tekrar giriş yapın."
    //         }))
    //         history.push('/login')
    //     }
    // }

    return <ThemeProvider theme={theme}>
        <contextDR.Provider value={contextProviderValues}>
            <div className="App">
                <div className="page-display" id={"app-element"}>
                    <DrSnackbar severity={snack.severity} open={snack.open} message={snack.message}/>
                    <Suspense fallback={<Loading/>}>
                        <Switch>
                            <Route path={["/login", "/giris"]} exact component={Login}/>
                            <Route path={["/register", "/kayit"]} exact component={Register}/>
                            <ProtectedRoute path={'*'} toRedirect={'/giris'}>
                                <Header/>
                                <Route path="/" exact component={Services}/>
                                <Route path="/customer-counter" exact component={CustomerCounter}/>
                                <Route path="/kisisayar" exact component={KisiSayarSDC}/>
                                <Route path={["/hedef-kitle", "/target-group"]} exact component={HedefKitleSDC}/>
                                {/*<Route path="/target-group" exact component={TargetGroupAnalysis}/>*/}
                                <Route path="/report" exact component={Reports}/>
                                <Route path={["/customer-satisfaction", "/müsteri-memnuniyet-analizi"]} exact
                                       component={CustomerSatisfaction}/>
                                <Route
                                    path={["/customer-satisfaction/create-survey", "/müsteri-memnuniyet-analizi/anket-yönetimi"]}
                                    exact component={CreateQuestionSet}/>
                                <Route
                                    path={["/customer-satisfaction/publish-questionSet", "/müsteri-memnuniyet-analizi/soru-seti-yayınlama"]}
                                    exact component={PublishQuestionSet}/>
                                <Route path={["/screen", "/ekran-yonetimi/ekranlar", "/ekran-yonetimi"]} exact
                                       component={ScreenManagement}/>
                                <Route path={["/screen/captures", "/ekran-yonetimi/goruntuler"]} exact
                                       component={ScreenCaptures}/>
                                <Route
                                    path={["/customer-satisfaction/emoji-management", "/müsteri-memnuniyet-analizi/emoji-yönetimi"]}
                                    exact component={EmojiManagement}/>
                                <Route path="/alarm" exact component={Alarm}/>
                                <Route path="/admin" exact component={Admin}/>
                                <Route path="/admin/main" exact component={AdminMain}/>
                                {/*<Route path="/admin/store-management" exact component={StoreGroupManagement}/>*/}
                                <Route path="/admin/minipc" exact component={MiniPcManagement}/>
                                <Route path="/admin/screen" exact component={SmartScreenPanel}/>
                                <Route path="/admin/sdc" exact component={AdminSdc}/>
                                <Route path="/profile" exact component={Profile}/>
                                <Route path="/insight" exact component={Insight}/>
                                <Route path={["/digital-signage", '/ekran-yonetimi/signage']} exact
                                       component={DigitalSignage}/>
                                <Route path={["/campaign-management", '/ekran-yonetimi/icerikler']} exact
                                       component={Campaigns}/>
                                <Route path={["/admin/ssh"]} exact
                                       component={Terminal}/>
                                {/*<Route component={NotFound}/>*/} {/*problemli*/}
                            </ProtectedRoute>

                        </Switch>
                    </Suspense>

                </div>
            </div>
        </contextDR.Provider>
        {/*<Footer/>*/}
    </ThemeProvider>
}

export default App;
